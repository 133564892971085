import Reveal from "../shared/Reveal";

const people = [

	{
		"TW": 1,
		"LW": 4,
		"TITLE": "APT.",
		"ARTIST": "ROSÉ,BRUNO MARS",
		"image": null
	  },
	  {
		"TW": 2,
		"LW": 1,
		"TITLE": "DIE WITH A SMILE",
		"ARTIST": "LADY GAGA,BRUNO MARS",
		"image": null
	  },
	  {
		"TW": 3,
		"LW": 15,
		"TITLE": "MESSY",
		"ARTIST": "LOLA YOUNG",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/d4/85/6b/d4856be7-64d4-5c70-5d5d-18552f826426/24UMGIM46883.rgb.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 4,
		"LW": 6,
		"TITLE": "BIRDS OF A FEATHER",
		"ARTIST": "BILLIE EILISH",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/25/1e/45/251e454e-dab5-1e0a-f216-52e77d5032c0/24UM1IM41181.rgb.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 5,
		"LW": 8,
		"TITLE": "THAT’S SO TRUE",
		"ARTIST": "GRACIE ABRAMS",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/26/78/81/26788143-5e5f-0813-2480-ecf4280ef221/24UM1IM07082.rgb.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 6,
		"LW": 5,
		"TITLE": "BAD DREAMS",
		"ARTIST": "TEDDY SWIMS",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/cd/5d/64/cd5d64c2-338b-00c9-52d6-578a0f19d826/054391245908.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 7,
		"LW": 3,
		"TITLE": "NICE TO MEET YOU",
		"ARTIST": "MYLES SMITH",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/c1/a5/e5/c1a5e5c4-7a40-ed20-ee53-1cfc97b22c55/196872574104.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 8,
		"LW": 10,
		"TITLE": "DIRTY CASH (MONEY TALKS)",
		"ARTIST": "PAWSA,THE ADVENTURES OF STEVIE V",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/e9/31/9c/e9319ccd-2482-110b-a118-f7bd7a20dec1/6417128100422.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 9,
		"LW": 2,
		"TITLE": "TIMELESS (WITH PLAYBOI CARTI)",
		"ARTIST": "THE WEEKND,PLAYBOI CARTI",
		"image": null
	  },
	  {
		"TW": 10,
		"LW": 26,
		"TITLE": "GOOD LUCK, BABE!",
		"ARTIST": "CHAPPELL ROAN",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/29/a7/c4/29a7c478-351d-25eb-a116-3e68118cdab8/24UMGIM31246.rgb.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 11,
		"LW": 13,
		"TITLE": "TASTE",
		"ARTIST": "SABRINA CARPENTER",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/f6/15/d0/f615d0ab-e0c4-575d-907e-1cc084642357/24UMGIM61704.rgb.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 12,
		"LW": 20,
		"TITLE": "GREENLIGHT (FEAT. FLO RIDA & LUNCHMONEY LEWIS)",
		"ARTIST": "PITBULL,FLO RIDA,LUNCHMONEY LEWIS",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/5e/55/ac/5e55ac3d-ffae-a2b6-a8ef-301785c7e66f/886445996326.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 13,
		"LW": 7,
		"TITLE": "AUSTIN (BOOTS STOP WORKIN')",
		"ARTIST": "DASHA",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/a3/89/c4/a389c41a-874c-52a6-d374-3d51fe76610a/093624843009.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 14,
		"LW": 35,
		"TITLE": "DIET PEPSI",
		"ARTIST": "ADDISON RAE",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/01/ef/7a/01ef7a06-1b48-0460-efbf-983d6a0a37fa/196872309959.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 15,
		"LW": 9,
		"TITLE": "LA LA LA",
		"ARTIST": "NAUGHTY BOY,SAM SMITH",
		"image": null
	  },
	  {
		"TW": 16,
		"LW": 19,
		"TITLE": "FOREVER YOUNG",
		"ARTIST": "DAVID GUETTA,ALPHAVILLE,AVA MAX",
		"image": null
	  },
	  {
		"TW": 17,
		"LW": 36,
		"TITLE": "BEAUTIFUL THINGS",
		"ARTIST": "BENSON BOONE",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/54/f4/92/54f49210-e260-b519-ebbd-f4f40ee710cd/054391342751.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 18,
		"LW": 12,
		"TITLE": "PRAISING YOU (FEAT. FATBOY SLIM)",
		"ARTIST": "RITA ORA,FATBOY SLIM",
		"image": null
	  },
	  {
		"TW": 19,
		"LW": 14,
		"TITLE": "STARGAZING",
		"ARTIST": "MYLES SMITH",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/bf/c3/a5/bfc3a56d-a51b-69cb-cdfc-26ee63631cb8/196872051872.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 20,
		"LW": 28,
		"TITLE": "I HAD SOME HELP (FEAT. MORGAN WALLEN)",
		"ARTIST": "POST MALONE,MORGAN WALLEN",
		"image": null
	  },
	  {
		"TW": 21,
		"LW": 40,
		"TITLE": "ESPRESSO",
		"ARTIST": "SABRINA CARPENTER",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/1f/c9/3b/1fc93bbf-42f1-4385-0a3a-34d60f4e0451/artwork.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 22,
		"LW": 21,
		"TITLE": "DEGENERE 👑 (FEAT. BENNY BLANCO)",
		"ARTIST": "MYKE TOWERS,BENNY BLANCO",
		"image": null
	  },
	  {
		"TW": 23,
		"LW": 11,
		"TITLE": "CRY BABY",
		"ARTIST": "CLEAN BANDIT,ANNE-MARIE,DAVID GUETTA",
		"image": null
	  },
	  {
		"TW": 24,
		"LW": 38,
		"TITLE": "LOSE CONTROL",
		"ARTIST": "TEDDY SWIMS",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/fc/22/32/fc223298-f4d8-99c5-1da4-a896c8ebd131/artwork.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 25,
		"LW": 18,
		"TITLE": "TOUCH",
		"ARTIST": "KATSEYE",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/08/fb/6a/08fb6a32-8856-f1f4-148b-8959bff0efb0/24UMGIM67773.rgb.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 26,
		"LW": 16,
		"TITLE": "SI ANTES TE HUBIERA CONOCIDO",
		"ARTIST": "KAROL G",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/cf/30/d2/cf30d2d2-58fe-fc2c-8d9a-8ce1e62e41d1/artwork.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 27,
		"LW": 34,
		"TITLE": "A BAR SONG (TIPSY)",
		"ARTIST": "SHABOOZEY",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/97/b5/bc/97b5bca2-6238-2421-4f0d-1f7a39d5d9d4/197342550925_cover.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 28,
		"LW": 22,
		"TITLE": "DANCING IN THE FLAMES",
		"ARTIST": "THE WEEKND",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/03/75/e4/0375e4fa-9331-3906-76a1-216478606608/24UMGIM95646.rgb.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 29,
		"LW": 24,
		"TITLE": "FORTNIGHT (FEAT. POST MALONE)",
		"ARTIST": "TAYLOR SWIFT,POST MALONE",
		"image": null
	  },
	  {
		"TW": 30,
		"LW": 37,
		"TITLE": "CRIMINALS",
		"ARTIST": "MEGHAN TRAINOR",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/cc/2c/2c/cc2c2c65-2301-41fa-966d-d4959341556e/196872330434.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 31,
		"LW": 27,
		"TITLE": "LUTHER (WITH SZA)",
		"ARTIST": "KENDRICK LAMAR,SZA",
		"image": null
	  },
	  {
		"TW": 32,
		"LW": 30,
		"TITLE": "MOONLIT FLOOR (KISS ME)",
		"ARTIST": "LISA",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/7c/83/fb/7c83fbdf-27c1-39d5-dfb6-b8d6e2cd8dad/196872478501.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 33,
		"LW": 23,
		"TITLE": "GUESS FEATURING BILLIE EILISH",
		"ARTIST": "CHARLI XCX,BILLIE EILISH",
		"image": null
	  },
	  {
		"TW": 34,
		"LW": 17,
		"TITLE": "BED CHEM",
		"ARTIST": "SABRINA CARPENTER",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/f6/15/d0/f615d0ab-e0c4-575d-907e-1cc084642357/24UMGIM61704.rgb.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 35,
		"LW": 25,
		"TITLE": "TOO SWEET",
		"ARTIST": "HOZIER",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/5b/a0/75/5ba075ed-8f8d-1966-5ea6-14b3eb2a9af2/artwork.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 36,
		"LW": 39,
		"TITLE": "DISEASE",
		"ARTIST": "LADY GAGA",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/c7/2b/dc/c72bdc7a-7014-e5df-a106-583074c0affa/24UM1IM12462.rgb.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 37,
		"LW": 29,
		"TITLE": "PUSH 2 START",
		"ARTIST": "TYLA",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/2a/cc/48/2acc48c7-e092-6b83-ce65-ff80ac6eb51c/196872520118.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 38,
		"LW": 33,
		"TITLE": "PINK PONY CLUB",
		"ARTIST": "CHAPPELL ROAN",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/7a/0c/bc/7a0cbc03-c6f6-b3b3-3fbe-9330adf84b92/25193.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 39,
		"LW": 31,
		"TITLE": "PEOPLE WATCHING",
		"ARTIST": "SAM FENDER",
		"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/bd/8c/24/bd8c2468-7978-cace-67b1-e0b3e5a643b8/24UM1IM05583.rgb.jpg/600x600bb.jpg"
	  },
	  {
		"TW": 40,
		"LW": 32,
		"TITLE": "WE PRAY",
		"ARTIST": "COLDPLAY,LITTLE SIMZ,BURNA BOY,ELYANNA,TINI",
		"image": null
	  }

  ];

export default function Chart() {
  return (
    <div className=" w-full mt-3 relative">
      <div className="flex flex-col items-start h-full  shadow-md rounded-md overflow-hidden w-full">
        <div className="bg-[#2b0b3d] border-b-2 px-4 py-3 w-full">
          <div className="flex justify-between items-center uppercase ">
            <div className="flex space-x-3">
              <p className="text-sm font-semibold text-white">This Week</p>
              <p className="text-sm font-semibold text-white">Name</p>
            </div>
            <div>
              <p className="text-sm font-semibold text-white">Last Week</p>
            </div>
          </div>
        </div>

        <ul className="divide-y divide-gray-700 overflow-y-auto h-80 sm:h-full max-h-[518px] w-full overflow-x-hidden scroll-design">
          {people.map((person, index) => (
            <Reveal key={index}
              hidden={{
                opacity: 0,
                x: index % 2 === 0 ? -75 : 75,
              }}
              visible={{ opacity: 1, x: 0 }}>
              <li
                key={index}
                className={`flex justify-between p-3 hover:bg-purple-200 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
              >
                <div className="flex items-center gap-3">
                  <p className="text-sm p-2 m-1 font-medium text-white bg-theme-third rounded-full h-[40px] w-[40px] flex items-center justify-center">
                    {String(person.TW).padStart(2, "0")}
                  </p>
                  <img
                    className="h-10 w-10 m-1 rounded-full"
                    src={person.image}
                    alt=""
                  />
                  <div className="flex flex-col items-start">
                    <p className="text-sm font-medium text-gray-800">
                      {person.TITLE}
                    </p>
                    <p className="text-sm text-gray-600">{person.ARTIST}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <p className="text-sm font-medium text-gray-500 ml-2">
                    {person.LW}
                  </p>
                </div>
              </li>
            </Reveal>
          ))}
        </ul>
      </div>
    </div >

  );
}
